import { createAction, createSelector } from '@ngrx/store';
import { ICountry } from '../../shared/models/country.interface';
import { AppState } from '../models/app.state';
import { SettingsState } from '../models/settings.state';
import { ITimeZone } from '../../shared/models/timezone.interface';
import { ILanguage } from '../../shared/models/language.interface';

const PREFIX = '[SETTINGS]'

export enum ActionTypes {
  LOAD_SETTINGS = `${PREFIX} LOAD SETTINGS`,
  POPULATE_SETTINGS = `${PREFIX} POPULATE SETTINGS`,

}

export const loadSettings = createAction(ActionTypes.LOAD_SETTINGS);
export const populateSettings = createAction(ActionTypes.POPULATE_SETTINGS, (data: {
  countries: ICountry[],
  timezones: ITimeZone[],
  languages: ILanguage[]
}) => (data));


export const selectSettingsState = (state: AppState) => state.settings;
export const selectCountries = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.countries
);

export const selectLanguages = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.languages
);

export const selectTimezones = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.timezones
);


